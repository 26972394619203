import React from 'react';
import moment from 'moment';

class Clock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            curDateTime: moment().format('YYYY-MM-DD HH:mm:ss').toString()
        };
    }

    render() {
        return (
            <div>{this.state.curDateTime}</div>
        );
    }

    componentDidMount() {
        this.secondsIntervalId = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.secondsIntervalId);
    }

    tick() {
        this.setState({
            curDateTime: moment().format('YYYY-MM-DD HH:mm:ss')
        });
    }
}

export default Clock;