import React from 'react';
import Clock from './Clock';
import moment from 'moment';
import './App.css';

function App() {
    let datetime = moment().format('YYYY-MM-dd HH:mm:ss').toString();
    return (
        <div className="App">
            <header className="App-header">
                headcode
                    <i>keep it simple</i>
                    <Clock></Clock>
            </header>
        </div>
    );
}

export default App;
